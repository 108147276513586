import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import { Link } from "gatsby";

const SectionStyled = styled(Section)``;

const FaqCard = ({ title, children, ...rest }) => (
  <Box {...rest}>
    <Title variant="card" mb={4} fontSize="24px" fontWeight="900">
      {title}
    </Title>
    {children}
  </Box>
);

const FAQ = () => (
  <>
    <SectionStyled bg="#F7F7FB">
      <Container>
        <Row className="justify-content-center">
          <Col xl="7" lg="8" md="9">
            <div className="text-center">
              <Title>
                Frequently
                <br className="d-none d-sm-block" /> Asked Questions
              </Title>
              <Text>Quick answers to common questions.</Text>
            </div>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col lg="6" className="mt-5 px-5">
            <FaqCard title="How does Opqo Free work?">
              <Text variant="small">
                Opqo installs a 5 user license as part of its configuration.
                This allows you to add up to 5 users to the Opqo security groups
                to use the app.
              </Text>
              <Text variant="small" className="mt-3">
                No other action is needed!
              </Text>
            </FaqCard>
          </Col>
          <Col lg="6" className="mt-5 px-5">
            <FaqCard title="Is there really no software to install?">
              <Text variant="small">
                None at all!
              </Text>
              <Text variant="small" className="mt-3">
                Simply download the app, tell Opqo your Maximo server name, login,
                and Opqo handles the rest.
              </Text>
            </FaqCard>
          </Col>
          <Col lg="6" className="mt-5 px-5">
            <FaqCard title="What changes does Opqo make to my Maximo environment?">
              <Text variant="small">
                We create some integration objects, setup some security groups and add some
                automation scripts.
                You can see all the details <a href="https://support.sharptree.io/support/solutions/articles/69000339542"
                  target="_blank" rel="noopener noreferrer"><u>here</u></a>.
              </Text>
            </FaqCard>
          </Col>
          <Col lg="6" className="mt-5 px-5">
            <FaqCard title="How can I remove the Opqo configuration from my Maximo environment?">
              <Text variant="small">
                One of the automation scripts that Opqo installs is an uninstall script that removes
                all Opqo Maximo configurations.
              </Text>
              <Text variant="small" className="mt-3">
                Details for enabling and executing this script are
                available <a href="https://support.sharptree.io/support/solutions/articles/69000339549"
                  target="_blank" rel="noopener noreferrer"><u>here</u></a>.
              </Text>
            </FaqCard>
          </Col>
          <Col lg="6" className="mt-5 px-5">
            <FaqCard title="Do you offer volume pricing?">
              <Text variant="small">
                We do! We are happy to offer discounts based on user volume and/or
                subscription period.
              </Text>
              <Text variant="small" className="mt-3">
                <Link to="/contact"><u>Contact us</u></Link> for more information.
              </Text>
            </FaqCard>
          </Col>
          <Col lg="6" className="mt-5 px-5">
            <FaqCard title="Are application updates included?">
              <Text variant="small">
                Yes. We regularly update Opqo to ensure support for current devices,
                and we are continuously adding new widgets, functions and support for new
                processes.
              </Text>
              <Text variant="small" className="mt-3">
                You can see details of our release history <a href="https://support.sharptree.io/support/solutions/folders/69000396919" target="_blank" rel="noopener noreferrer">
                  <u>here</u></a>.
              </Text>
            </FaqCard>
          </Col>
          <Col lg="6" className="mt-5 px-5">
            <FaqCard title="What devices does Opqo support?">
              <Text variant="small">
                Opqo supports both phone and tablet devices running
                iOS 16+ and Android 8+.
              </Text>
            </FaqCard>
          </Col>
          <Col lg="6" className="mt-5 px-5">
            <FaqCard title="What versions of Maximo do you support?">
              <Text variant="small">
                We support Maximo 7.6.0.9 and 7.6.0.10, Maximo 7.6.1.x and Maximo Application Suite 8 and 9.
              </Text>
            </FaqCard>
          </Col>
          <Col lg="6" className="mt-5 px-5">
            <FaqCard title="Do you support Maximo Application Suite?">
              <Text variant="small">
                You bet! We communicate with Maximo using the standard REST API.
                With no additional software to install, there are no issues with
                containerization of Maximo.
              </Text>
            </FaqCard>
          </Col>
          <Col lg="6" className="mt-5 px-5">
            <FaqCard title="How does the app find my Maximo environment?">
              <Text variant="small">
                We have a number of ways to make it easy for your users to connect,
                including support for MDM configuration, DNS autodiscovery, and a customer console that allows
                you to associate your Maximo environment with a connection code of your choice.
              </Text>
            </FaqCard>
          </Col>
          <Col lg="6" className="mt-5 px-5">
            <FaqCard title="I need to support some very specific business processes">
              <Text variant="small">
                Please <Link to="/contact"><u>contact us</u></Link> and we can advise
                whether there is an assembly that will meet your need now or on the roadmap.
                If not, we'd love to discuss your process in more detail.
              </Text>
            </FaqCard>
          </Col>
          <Col lg="6" className="mt-5 px-5">
            <FaqCard title="Where can I find more information?">
              <Text variant="small" className="mt-3">
                Check out our <a href="https://support.sharptree.io/support/solutions" target="_blank" rel="noopener noreferrer">
                  <u>Knowledge Base</u></a>.
              </Text>
            </FaqCard>
          </Col>

          <Col lg="12" className="">
            <Box className="text-center" pt={5}>
              <Text>
                Didn’t find your answer? <Link to="/contact"
                  css={`
                    color: ${({ theme }) => theme.colors.primary} !important;
                  `}
                >
                  <u>Contact us here</u>
                </Link>
              </Text>
            </Box>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default FAQ;
