import React from "react";

import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";

import Hero from "../sections/landing/Hero";
import Feature from "../sections/landing/Feature";
// import Clients from "../sections/landing/Clients";
import Content1 from "../sections/landing/Content1";
import ApproachTagline from "../sections/landing/ApproachTagline";
import Content2 from "../sections/landing/Content2";
import Content3 from "../sections/landing/Content3";
import AppFeature from "../sections/landing/AppFeature";
import Testimonial from "../sections/landing/Testimonial";
// import Fact from "../sections/landing/Fact";
import Pricing from "../sections/landing/Pricing";
import PricingMoreDetail from "../sections/landing/PricingMoreDetail";
// import Faq from "../sections/landing/Faq";
import CTA from "../sections/landing/CTA";

import imgMetaCardSquare from "../assets/images/card_homepage_square.jpg";
import imgMetaCardTwitter from "../assets/images/card_homepage_twitter.jpg";

const IndexPage = (props) => {
  return (
    <>
      <PageWrapper headerDark footerDark>
        <Seo
          title="Opqo | Sharptree | Mobile for Maximo"
          description="Opqo is a simpler approach to mobility for Maximo. We’ve created an elegant mobile application that is simple to buy, easy to deploy, and delightful to use."
          image={imgMetaCardSquare}
          twitterImage={imgMetaCardTwitter}
          pathname={props.location.pathname}
        />
        <Hero />
        <Feature />
        <ApproachTagline />
        {/* <Clients /> */}
        <Content1 />
        <Content2 />
        <Content3 />
        <AppFeature />
        <Testimonial />
        {/* <Fact /> */}
        <Pricing />
        <PricingMoreDetail />
        {/* <Faq /> */}
        <CTA />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
