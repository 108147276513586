import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text, Box } from "../../components/Core";
import { device } from "../../utils";

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const SectionStyled = styled(Section)`
    background-color: ${({ theme }) => theme.colors.bg};
`;

const StyledImage = styled(Box)`
  border-radius: 10px;
  overflow: hidden;
  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
`;

const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 15px;
  padding-left: 0;

  @media ${device.sm} {
    padding-top: 35px;
    flex-wrap: wrap;
  }
  @media ${device.lg} {
    justify-content: space-between;
  }

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #19191b;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 9px;
      margin-right: 13px;
    }
  }
`;

const Content1 = () => {

  const data = useStaticQuery(graphql`
    query {
      tablet_detail_content: file(relativePath: { eq: "tablet_detail_content.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      {/* <!-- Content section 1 --> */}
      <SectionStyled
        className="position-relative"
        py={["50px", null, "130px", null, "150px"]}
      >
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className=" position-static order-lg-2">
              <Box
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <StyledImage>
                  <Img
                    fluid={data.tablet_detail_content.childImageSharp.fluid}
                  />
                </StyledImage>
              </Box>
            </Col>
            <Col lg="6" className="order-lg-1 mt-5 mt-lg-0">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <div>
                  <Title>
                    Work orders,<br /> simplified.
                  </Title>
                  <Text mb={0}>
                    The most relevant details at a glance.
                  </Text>
                  <ULStyled>
                    <li>Process-oriented</li>
                    <li>Clearly organized work activities</li>
                    <li>Context-based information</li>
                    <li>Supports role-based layouts</li>
                  </ULStyled>
                  <Text mt={4}>
                    Opqo provides a simple yet effective format for getting vital information to
                    your personnel in the field.
                  </Text>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Content1;
