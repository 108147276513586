import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Box } from "../../components/Core";

const ApproachTagline = () => (
    <>
        <Box bg="greyBackground" className="position-relative" py={[4, null, null, null, 5]}>
            <Container>
                <Row className="justify-content-center">
                    <Col lg="10">
                        <Box className="text-center">
                            <Title variant="quote" color="ash">
                                Deploy, use, adjust, improve.
                            </Title>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </Box>
    </>
);

export default ApproachTagline;
