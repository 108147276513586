import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

const SectionStyled = styled(Section)`
  padding-top: 47px;
  padding-bottom: 23px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
  @media ${device.md} {
    padding-top: 87px;
    padding-bottom: 63px;
  }
`;

const FeatureCard = ({ iconName, title, children, iconColor, ...rest }) => (
  <Box px={3} {...rest}>
    <Box mb={[3, 3, 3, 3]} pb={2} fontSize="48px" color={iconColor}>
      <i className={`fa ${iconName}`} ></i>
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      {children}
    </Box>
  </Box>
);

const Feature = () => (
  <>
    {/* <!-- Feature section --> */}
    <SectionStyled>
      <Container>
        <Row className="justify-content-center">
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard title="No upfront project" iconName="fa-calendar-times" iconColor="accentOrange">
              <Text variant="small">
                We think mobility for IBM&reg; Maximo&reg; shouldn’t require a huge upfront effort, risk, or commitment.
              </Text>
              <Text mt={3} variant="small">
                With Opqo, start small with zero upfront costs or commitment, then only pay for what you use.
              </Text>
            </FeatureCard>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard title="Up and running in minutes" iconName="fa-stopwatch" iconColor="accentGreen">
              <Text variant="small">
                With nothing to install, simply download the app, tell Opqo your Maximo server name, login,
                and Opqo handles the rest.
              </Text>
              <Text mt={3} variant="small">
                Opqo configures Maximo automatically, in just a few minutes, so you can get going right away.
              </Text>
            </FeatureCard>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard title="Configure for now, and later" iconName="fa-sync" iconColor="accentBlue">
              <Text mt={3} variant="small">
                Your business doesn’t stop improving and neither should your mobile.
              </Text>
              <Text mt={3} variant="small">
                Opqo is configurable, right from your Maximo instance.
              </Text>
              <Text mt={3} variant="small">
                Configure Opqo to meet your needs now and re-configure as your needs change.
              </Text>
            </FeatureCard>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Feature;
